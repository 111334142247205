import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import apiService from "../../apiService";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import useBodyOverflow from "../../hooks/useBodyOverflow";

const VendorModel = ({ isOpen, onClose, fetchData, vendor }) => {
  const [loading, setLoading] = useState(false);
  const [camps, setCamps] = useState([]);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const validationSchema = Yup.object({
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: !vendor ? Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Z]/, "Password must contain an uppercase letter")
      .matches(/[0-9]/, "Password must contain a number")
      .matches(/[!@#$%^&*]/, "Password must contain a special character")
      .required("Password is required") : Yup.string(),
    selectedCamps: Yup.array().min(1, "At least one campaign must be selected"),
  });

  const initialValues = {
    username: vendor ? vendor.name : "",
    email: vendor ? vendor.email : "",
    password: "",
    selectedCamps: vendor ? camps?.filter((c) => vendor?.campIds?.includes(c?.value)) || [] : [],
  };

  useBodyOverflow(isOpen)

  useEffect(() => {
    if (isOpen) { fetchQuestions() }
  }, [isOpen]);

  const fetchQuestions = async () => {
    try {
      const result = await apiService.campaigns.get("", 0, -1);
      const options = result.data.campaigns.map((camps) => ({
        label: camps.title,
        value: camps._id,
      }));
      setCamps(options);
    } catch (error) {
      toast.error("Failed to fetch campaigns.");
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    const userData = {
      name: values.username,
      email: values.email,
      password: values.password,
      campIds: values.selectedCamps.map((camp) => camp.value),
      userType: "vendor",
    };

    try {
      let response;
      if (vendor) {
        response = await apiService.auth.updateUser(vendor._id, userData);
      } else {
        response = await apiService.auth.register(userData);
      }

      if (response?.success) {
        fetchData();
        toast.success(`Vendor ${vendor ? "updated" : "created"} successfully!`);
        onClose();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(`Error ${vendor ? "updating" : "creating"} user:`, error);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    isOpen && (
      <div className="modal-overlay">
        <div
          className="modal max-h-[90%] overflow-y-auto pt-5 sm:px-8 sm:pb-10 px-4 pb-5 relative   max-w-[700px] mx-auto w-full"
        >
          <div className=" flex justify-end absolute top-5 right-5">
            <button
              type="button"
              className="rounded-full h-[30px] w-[30px] text-sm border-2  border-black  font-semibold"
              onClick={onClose}
            >
              <i className="fa-solid text-black text-lg fa-xmark"></i>
            </button>
          </div>
          <h2 className="text-center font-bold text-xl mt-8">
            {vendor ? "Edit Vendor" : "Add Vendor"}
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue, isValid, isSubmitting }) => (
              <Form className="mt-10">
                <div className="flex items-center">
                  <label className="font-semibold text-base leading-5 flex-[4]">
                    Username
                  </label>
                  <div className="flex-[8]">
                    <Field
                      type="text"
                      name="username"
                      placeholder="Username"
                      className="outline-none w-full bg-[#F2F2F2] p-2 font-medium text-sm"
                    />
                    <ErrorMessage name="username" component="div" className="text-red-500 text-sm" />
                  </div>
                </div>
                <div className="mt-3 flex items-center">
                  <label className="font-semibold text-base leading-5 flex-[4]">
                    Email
                  </label>
                  <div className="flex-[8]">
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="outline-none w-full bg-[#F2F2F2] p-2 font-medium text-sm"
                    />
                    <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
                  </div>
                </div>
                <div className="mt-3 flex items-center">
                  <label className="font-semibold text-base leading-5 flex-[4]">
                    Password
                  </label>
                  <div className="flex-[8] relative">
                    <Field
                      type={passwordVisible ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      className="outline-none w-full bg-[#F2F2F2] p-2 font-medium text-sm"
                      required={!vendor}
                    />
                    <ErrorMessage name="password" component="div" className="text-red-500 text-sm" />
                    <i
                      className={`fa-solid ${passwordVisible ? "fa-eye" : "fa-eye-slash"} absolute right-4 top-3 cursor-pointer`}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    ></i>
                  </div>
                </div>

                <div className="relative border border-[#C4C4C4] rounded-[5px] px-3 py-4 mt-[34px]">
                  <span className="absolute top-2 left-1 font-semibold text-base leading-5 transform -translate-y-[100%] bg-white text-black">
                    Select Fields
                  </span>
                  <div className="flex items-center">
                    <label className="font-semibold text-base leading-5 flex-[4]">
                      Select Campaign
                    </label>
                    <div className="flex-[8]">
                      <Multiselect
                        options={camps}
                        selectedValues={values.selectedCamps}
                        displayValue="label"
                        onSelect={(selectedList) => setFieldValue("selectedCamps", selectedList)}
                        onRemove={(selectedList) => setFieldValue("selectedCamps", selectedList)}
                        placeholder="Select campaigns"
                        style={{
                          chips: {
                            background: "#F6F6F6",
                            color: "black",
                            fontWeight: "600",
                          },
                        }}
                      />
                      <i className="fas fa-chevron-down absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"></i>
                    </div>
                  </div>
                </div>

                <div className="mt-10 flex justify-center">
                  <button
                    type="submit"
                    className={`py-3 px-10 rounded-[15px] text-sm bg-black text-white font-semibold ${(!isValid || isSubmitting) ? "opacity-50 cursor-not-allowed" : ""}`}
                    disabled={!isValid || isSubmitting || !values.selectedCamps.length}
                  >
                    {loading ? (
                      <div className="loader" />
                    ) : vendor ? (
                      "Update"
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  );
};

export default VendorModel;
