import { createBrowserRouter, Navigate } from "react-router-dom";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout.jsx";
import UnauthenticatedLayout from "../layouts/UnauthenticatedLayout.jsx";
import DashBoard from "../pages/dashboard/DashBoard.jsx";
import Leads from "../pages/leads/Leads.jsx";
import Clients from "../pages/clients/Clients.jsx";
import Vendors from "../pages/vendors/Vendors.jsx";
import Staff from "../pages/staff/Staff.jsx";
import Login from "../pages/login/Login.jsx";
import PrivateRoute from "./privateRoutes.js";
import Unauthorized from "../components/shared/Unauthorized.jsx";
import NotFound from "../components/shared/NotFound.jsx";
import Question from "../pages/question/Question.jsx";
import LeadsForm from "../components/forms/Lead.jsx";
import Request from "../pages/request/Request.jsx";
import Campaigns from "../pages/campaign/Campaign.jsx";
import LeadView from "../components/views/LeadView.jsx";
import AdminLeadView from "../components/views/AdminLeadView.jsx";
import ApiLeadsForm from "../pages/vendors/ApiLeadsForm.jsx";
import SubAdmin from "../pages/sub-admin";

const router = createBrowserRouter([
  {
    path: "/",
    element: <UnauthenticatedLayout />,
    children: [
      {
        path: "",
        element: <Login />,
      },
    ],
  },
  {
    path: "crm",
    element: (
      <PrivateRoute
        element={<AuthenticatedLayout />}
        allowedRoles={["staff", "vendor", "admin", "client", "subAdmin"]}
      />
    ),
    children: [
      { path: "", element: <Navigate to="dashboard" replace /> },
      {
        path: "dashboard",
        element: (
          <PrivateRoute element={<DashBoard />} allowedRoles={["admin" , "vendor", "staff", 'client', 'subAdmin']} />
        ),
      },
      {
        path: "leads",
        element: (
          <PrivateRoute element={<Leads />} allowedRoles={["admin", "staff" ,"vendor", 'subAdmin']} />
        ),
      },
      {
        path: "leads/view/:id",
        element: (
          <PrivateRoute element={<AdminLeadView />} allowedRoles={["admin"]} />
        ),
      },
      {
        path: "leads/create/:campId",
        element: (
          <PrivateRoute element={<LeadsForm />} allowedRoles={["staff" ,"vendor"]} />
        ),
      },
      {
        path: "leads/edit/:id",
        element: <PrivateRoute element={<LeadsForm isEditMode={true} />} allowedRoles={["staff", "vendor", "admin"]} />,
      },
      {
        path: "lead/verifier/:id",
        element: <PrivateRoute element={<LeadView />} allowedRoles={["staff", "vendor"]} />,
      },
      {
        path: "clients",
        element: (
          <PrivateRoute element={<Clients />} allowedRoles={["admin", "vendor", 'client']} />
        ),
      },
      {
        path: "vendors",
        element: (
          <PrivateRoute element={<Vendors />} allowedRoles={["admin", "vendor"]} />
        ),
      },
      {
        path: "vendor/lead/create/api",
        element: (
          <PrivateRoute element={<ApiLeadsForm />} allowedRoles={["vendor"]} />
        ),
      },
      {
        path: "question",
        element: (
          <PrivateRoute element={<Question />} allowedRoles={["admin"]} />
        ),
      },
      {
        path: "staff",
        element: (
          <PrivateRoute element={<Staff />} allowedRoles={["admin"]} />
        ),
      },
      {
        path: "campaigns",
        element: (
          <PrivateRoute element={<Campaigns />} allowedRoles={["admin"]} />
        ),
      },
      {
        path: "request",
        element: (
          <PrivateRoute element={<Request />} allowedRoles={["admin", "staff"]} />
        ),
      },
      {
        path: "sub-admin",
        element: (
          <PrivateRoute element={<SubAdmin />} allowedRoles={["admin"]} />
        ),
      },
    ],
  },
  {
    path: "unauthorized",
    element: <Unauthorized />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
