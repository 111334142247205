import React, { useEffect, useState, useCallback, useRef } from "react";
import ApiService from "../../apiService";
import SkeletonLoader from "../skeletonloader/SkeletonLoader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  roleList,
  allStatus,
  adminStatusList,
  onlyAdminStatus,
  afterSubmitToAto,
  beforeSubmitToAtoFAdmin,
  onlyStaffStatus,
} from "../../utils/constant";
import apiService from "../../apiService";
import { isAdmin } from "../../utils/auth";
import Pagination from "../shared/Pagination";
import ClientViewModel from "../model/ClientView";
import "react-dropdown/style.css";
import { exportToExcel, getLeadData, isUserAdminOrSubAdmin, trimText } from "../../utils/function";
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg';
const Dropdown = React.lazy(() => import('react-dropdown'));

const LeadsTable = ({ title, userType }) => {
  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [leadToDelete, setLeadToDelete] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const [openSendToClient, setOpenSendToClient] = useState(false);
  const [selectedLead, setSelectedLead] = useState({});
  const [campaignDetails, setCampaignDetails] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("select");
  const [selectedRole, setSelectedRole] = useState(roleList[0].value);
  const [selectedUser, setSelectedUser] = useState("");
  const [assigneeList, setAssigneeList] = useState([]);
  const [campList, setCampList] = useState([]);
  const [selectedCamp, setSelectedCamp] = useState("select");
  const [downloadModel, setDownloadModel] = useState(false);
  const [userList, setUserList] = useState([]);

  const downloadRef = useRef(null);

  const isUserAdmin = isAdmin();
  const navigate = useNavigate();

  const openDownloadModel = () => {
    setDownloadModel(true);
  };
  const closeDownloadModel = () => {
    setDownloadModel(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (downloadRef.current && !downloadRef.current.contains(event.target)) {
        closeDownloadModel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchData = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const result = await ApiService.leads.getLeads({
          userType,
          selectedStatus: selectedStatus === "select" ? "" : selectedStatus,
          page,
          selectedUser,
          limit: rowsPerPage,
          selectedCamp: selectedCamp === "select" ? "" : selectedCamp,
          selectedRole
        });
        if (result?.success) {
          const { data } = result;
          setLeads(data.leads || []);
          setTotalPages(data.totalPages || 1);
          setCurrentPage(data.currentPage || 1);
          setTotalData(data.totalLeads);
        }
      } catch (error) {
        toast.error("Failed to fetch leads.");
      } finally {
        setLoading(false);
      }
    },
    [userType, selectedStatus, selectedUser, rowsPerPage, selectedCamp, selectedRole]
  );

  const exportAllData = async () => {
    const data = await apiService.leads.getAllLeads();
    exportToExcel(data?.data?.leads);
  };

  // initial fetch functions 
  const fetchCampaignDetails = async () => {
    try {
      if (!isUserAdminOrSubAdmin(userType)) {
        const result = await apiService.campaigns.getWithDetails();
        const campaigns = result?.data?.campaigns || [];
        setCampList(
          campaigns?.map((ca) => ({ label: ca?.title, value: ca.campId }))
        );
        setCampaignDetails(campaigns);
      }
    } catch (error) {
      toast.error("Failed to fetch campaign details.");
    }
  };

  const fetchAssignees = async () => {
    try {
      const result = await apiService.auth.getUser("staff", "", -1, "verifier");
      const assignees = result?.data?.users || [];
      setAssigneeList(
        assignees.map((ass) => ({ label: ass.name, value: ass._id }))
      );
    } catch (error) {
      toast.error("Failed to fetch assignees.");
    }
  };

  const fetchUsers = async () => {
    try {
      if (userType === 'subAdmin' || (selectedRole && selectedRole !== "all")) {
        const result = await apiService.auth.getUser(selectedRole);
        const users = result?.data?.users || [];
        setUserList(
          users.map((user) => ({ label: user.name, value: user._id }))
        );
      } else {
        setUserList([]);
        setSelectedUser("");
      }
    } catch (error) {
      toast.error("Failed to fetch user list.");
    }
  };

  const fetchInitialData = useCallback(async () => {
    setLoading(true);
    await Promise.all([fetchCampaignDetails(), fetchAssignees(), fetchUsers()]);
    setLoading(false);
  }, [userType, selectedRole]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  useEffect(() => {
    fetchData(currentPage);
  }, [fetchData, currentPage, selectedStatus, selectedUser, rowsPerPage]);

  const handleEditLead = (lead, campaignId) => {
    navigate(`/crm/leads/edit/${lead.leadId}/?campaignId=${campaignId}`);
  };

  const openDeleteConfirmation = (leadId) => {
    setLeadToDelete(leadId);
    setIsDeleteConfirmationOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
    setLeadToDelete(null);
    document.body.style.overflow = "unset";
  };

  const handleDelete = async () => {
    if (!leadToDelete) return;
    setIsDeleting(true);
    try {
      await ApiService.leads.deleteLeads(leadToDelete);
      fetchData();
      toast.success("Lead deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete lead.");
    } finally {
      setIsDeleting(false);
      closeDeleteConfirmation();
    }
  };

  const handleDropdownChange = (option) => {
    setSelectedStatus(option.value);
  };

  const handleUserChange = (option) => {
    setSelectedUser(option.value);
  };

  const handleRoleChange = (option) => {
    setSelectedRole(option.value);
  };

  const onAssigneeChange = async (assigneeId, leadId) => {
    try {
      const updatedLead = await apiService.leads.updateLead(leadId, {
        verifier: assigneeId,
        status: "under_verification",
      });
      if (updatedLead.success) {
        toast.success(updatedLead.message);
        setLeads((prevLeads) =>
          prevLeads.map((lead) =>
            lead.leadId === leadId ? { ...lead, verifier: assigneeId } : lead
          )
        );
        fetchData();
      }
    } catch (error) {
      toast.error("Failed to update assignee.");
    }
  };

  const getAssigneeLabel = (id) => {
    let label = assigneeList.find((user) => user.value === id)?.label;
    return label || "";
  };

  const getStatusLabel = (status) => {
    let label = allStatus.find((s) => s.value === status)?.label;
    return label || "";
  };

  const getLeadStatus = (status) => {
    if (
      userType !== "admin" &&
      userType !== "vendor" &&
      onlyAdminStatus.includes(status)
    ) {
      return "Submitted To Attorney";
    }

    return allStatus.find((d) => d.value === status)?.label || status;
  };

  const onStatusChange = async (leadId, status) => {
    try {
      const data = await apiService.leads.updateLead(leadId, { status });
      if (data.success) {
        toast.success(data?.message || "");
        fetchData();
      }
    } catch (error) {
      console.error("Failed to update lead status:", error);
    }
  };

  const canDisableSendToClient = (lead) => {
    return lead.status === 'reject' || (lead.createdBy.userType === "staff" && lead.status !== "verified")
  };

  const getUserStatus = (status, role) => {
    // uncomment this if you want to remove options in status submitted_to_attorney
    // if(status === 'submitted_to_attorney') {
    //   return []
    // } else
    if (
      status !== "submitted_to_attorney" &&
      !afterSubmitToAto.includes(status)
    ) {
      if (role === "vendor") {
        return [];
      }
      return beforeSubmitToAtoFAdmin;
    } else {
      if (role === "staff") {
        return adminStatusList.filter(
          (option) =>
            afterSubmitToAto.includes(option.value) &&
            !["billable", "paid"].includes(option.value)
        );
      } else if (role === "vendor") {
        return adminStatusList.filter((option) =>
          afterSubmitToAto.includes(option.value)
        );
      }
    }
    return adminStatusList;
  };

  const getFilterStatus = () => {
    if (userType === "admin" || userType === 'subAdmin') {
      return allStatus;
    }
    return allStatus.filter((s) =>
      userType === "staff"
        ? !onlyAdminStatus.includes(s.value)
        : !onlyAdminStatus.includes(s.value) &&
        !onlyStaffStatus.includes(s.value)
    );
  };

  const getDropdownConfig = (lead, assigneeList) => {
    if (lead.status === "new" && lead.createdBy.userType === "staff") {
      return {
        options: [{ label: "Assignee", value: "" }, ...assigneeList],
        onChange: (value) => onAssigneeChange(value, lead.leadId),
        value: getAssigneeLabel(lead?.verifier),
        placeholder: "Select an Assignee",
      };
    }

    return {
      options: getUserStatus(lead.status, lead.createdBy.userType),
      onChange: (value) => onStatusChange(lead.leadId, value),
      value: getStatusLabel(lead?.status),
      placeholder: "Status",
    };
  };

  // table cols
  const columns = [
    {
      key: "id",
      header: "Id",
      width: "w-[5%]",
      render: (lead) => lead?.leadId?.replaceAll("lead-", " "),
    },
    {
      key: "name",
      header: "Name",
      width: "w-[10%]",
      render: (lead) => getLeadData(lead?.responses, "name") || lead?.createdBy.name,
    },
    {
      key: "contact",
      header: "Contact",
      width: "w-[10%]",
      render: (lead) => getLeadData(lead?.responses, "number") || "-",
    },
    {
      key: "campaign",
      header: "Campaign",
      width: "w-[10%]",
      render: (lead) => lead?.campaign?.title || "-",
    },
    isUserAdminOrSubAdmin(userType) && {
      key: "createdBy",
      header: "Created by",
      width: "w-[10%]",
      render: (lead) => lead?.createdBy?.name || "-",
    },
    {
      key: "status",
      header: "Status",
      width: "w-[15%]",
      prevent: true,
      render: (lead) =>
        isUserAdminOrSubAdmin(userType) ? (
          (() => {
            const { options, onChange, value, placeholder } = getDropdownConfig(
              lead,
              assigneeList
            );
            return (
              <Dropdown
                options={options}
                onChange={(e) => {
                  onChange(e.value)
                }}
                value={value}
                placeholder={placeholder}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                className="font-medium"
              />
            );
          })()
        ) : (
          getLeadStatus(lead.status)
        ),
    },
    isUserAdminOrSubAdmin(userType) && {
      key: "action",
      header: "Action",
      width: "w-[5%]",
      prevent: true,
      render: (lead) => (
        <div className="flex items-center justify-center gap-1 w-full h-full">
          {isUserAdmin &&
            <>
              <button
                className="h-6 w-6"
                onClick={(event) => {
                  handleEditLead(lead, lead?.campaign?._id)
                }}
              >
                <img src="/assets/svg/edit.svg" alt="Edit" />
              </button>
              <button
                className="h-5 w-5"
                onClick={(event) => {
                  openDeleteConfirmation(lead.id)
                }}
              >
                <img src="/assets/svg/delete.svg" alt="Delete" />
              </button>
            </>
          }

          <button
            disabled={canDisableSendToClient(lead)}
            onClick={(event) => {
              setSelectedLead(lead);
              setOpenSendToClient(true);
            }}
          >
            <SendIcon
              style={{ fill: canDisableSendToClient(lead) ? "gray" : "#7FB8EF" }}
            />
          </button>
        </div>
      ),
    },
  ].filter(Boolean);

  return (
    <>
      <div className="flex sm:justify-end justify-between">
        {(!isUserAdmin && userType !== 'subAdmin') && (
          <div className=" w-full flex items-center gap-2 flex-wrap justify-center py-8 border bg-white border-border-dark rounded-[20px]">
            {campaignDetails?.map((camp) => (
              <button
                className="bg-blue-200 text-black font-bold py-2 px-4 rounded-full relative group"
                onClick={() => navigate(`/crm/leads/create/${camp.campId}`)}
              >
                {campaignDetails.length > 2 ? trimText(camp?.title, 15) : camp?.title}
                <span className="absolute top-full left-7 mt-2 w-max px-2 py-1 bg-gray-700 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-50">
                  {camp?.title}
                </span>
              </button>
            ))}
          </div>
        )}
      </div>

      <div className="border bg-white border-border-dark mt-4 rounded-[20px] overflow-hidden">
        <div className="xl:grid flex justify-between max-md:flex-col grid-cols-1 lg:grid-cols-2 md:gap-4 gap-2 py-1 px-2 w-full relative">
          <h1 className="font-bold p-2 leading-8 custom-padding xl:!text-end max-md:text-center  text-2xl col-span-1">
            {title}
          </h1>
          <div className="flex  max-sm:flex-wrap  justify-center md:justify-end items-center max-md:pb-4 gap-2 col-span-2 sm:col-span-1">
            {!isUserAdminOrSubAdmin(userType) && (
              <Dropdown
                options={[
                  { label: "Select Campaign", value: "select" },
                  { label: "All", value: "" },
                  ...campList,
                ]}
                onChange={(option) => setSelectedCamp(option.value)}
                value={selectedCamp}
                placeholder="All"
                className="font-medium shrink-0 min-w-dropdown"
              />
            )}
            {isUserAdmin && (
              <Dropdown
                options={roleList}
                onChange={handleRoleChange}
                value={selectedRole}
                placeholder="Select an Role"
                className="font-medium shrink-0"
              />
            )}
            {isUserAdminOrSubAdmin(userType) && (
              <Dropdown
                options={[{ label: 'All', value: '' }, ...userList]}
                onChange={handleUserChange}
                value={selectedUser}
                placeholder="Select User"
                className="font-medium shrink-0 min-w-dropdown"
                disabled={selectedRole === "all"}
              />
            )}
            <Dropdown
              options={[
                { label: "Select Status", value: "select" },
                { label: "All", value: "" },
                ...getFilterStatus(),
              ]}
              onChange={handleDropdownChange}
              value={selectedStatus}
              placeholder="All"
              className="font-medium  shrink-0 min-w-dropdown"
            />
            <div className=" relative">
              <div
                onClick={openDownloadModel}
                className=" bg-[#F9F9F9] px-2 py-[7px] flex items-center  flex-none text-base cursor-pointer font-medium text-start  border border-border-dark rounded-[5px] text-[#333]"
              >
                Export{" "}
                {!downloadModel ? (
                  <span>
                    <i className="fa-solid fa-caret-down text-[#999] ml-3 mt-1"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa-solid fa-caret-up text-[#999] ml-3 mt-1"></i>
                  </span>
                )}
              </div>
              {downloadModel && (
                <div
                  className="absolute top-12 z-50 shadow-lg rounded-lg right-0 p-2 bg-white "
                  ref={downloadRef}
                >
                  <div className="w-[150px] relative rounded-lg px-2">
                    <div className="flex flex-col">
                      <div
                        className="text-black px-1 py-2 text-sm cursor-pointer font-medium text-start"
                        onClick={() => exportAllData()}
                      >
                        All
                      </div>
                      <div
                        className="text-black px-1 py-3 text-sm cursor-pointer font-medium text-start"
                        onClick={() => exportToExcel(leads)}
                      >
                        Filter
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="w-full border-t border-border-dark min-h-[300px] overflow-x-auto">
          {loading && <SkeletonLoader />}
          {!loading &&
            (leads.length === 0 ? (
              <p className="text-center py-4">No leads found</p>
            ) : (
              <table className="w-full border-collapse scrolldown">
                <thead>
                  <tr className="bg-[#F6F6F6] h-[41.9px]">
                    {columns.map((col) => (
                      <th
                        key={col.key}
                        className={`text-base font-bold px-4 border-r border-border-dark ${col.width}`}
                      >
                        {col.header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white w-full">
                  {leads.map((lead) => (
                    <tr
                      key={lead.leadId}
                      className="border-b border-border-dark"
                      style={{ cursor: isUserAdmin ? "pointer" : "default" }}
                      onClick={() => {
                        if (isUserAdmin) {
                          navigate(`/crm/leads/view/${lead.leadId}`);
                        }
                      }}

                    >
                      {columns.map((col) => (
                        <td
                          key={col.key}
                          className={`text-sm text-center font-bold px-2 py-3 border-r border-border-dark ${col.textStyle || ""}`}
                          onClick={(e) => {
                            col.prevent && e.stopPropagation()
                          }}
                        >
                          {col.render(lead)}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ))}
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalData={totalData}
          onPageChange={setCurrentPage}
          loading={loading}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(+e.target.value);
            setCurrentPage(1);
          }}
        />

        <ClientViewModel
          isOpen={openSendToClient}
          onClose={() => {
            setOpenSendToClient(false);
          }}
          fetchData={fetchData}
          lead={selectedLead}
        />

        {isDeleteConfirmationOpen && (
          <div className="fixed inset-0 z-[1000] bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg p-4">
              <p className="text-lg font-semibold py-10">
                Are you sure you want to delete this lead?
              </p>
              <div className="flex justify-end mt-4 font-semibold">
                <button
                  className={`bg-[#AC1218] text-white py-1 ${isDeleting ? " px-6" : "px-2"
                    } text-sm font-semibold rounded-md`}
                  onClick={handleDelete}
                  disabled={isDeleting}
                >
                  {isDeleting ? <div className="loader"></div> : "Delete"}
                </button>
                <button
                  className="ml-4 border border-border-color py-1 px-2 text-sm rounded-md"
                  onClick={closeDeleteConfirmation}
                  disabled={isDeleting}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LeadsTable;
