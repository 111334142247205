import React from "react";
import useBodyOverflow from "../../hooks/useBodyOverflow";

const ApiLogsModel = ({ isOpen, onClose, json }) => {

    useBodyOverflow(isOpen)
    
    if (!isOpen) return null;

    return isOpen && (
        <div className="modal-overlay">
            <div className="modal pt-5 sm:px-8 sm:pb-10 max-h-[90%] overflow-y-auto px-4 pb-5 relative max-w-[700px] mx-auto w-full">
                <div className="flex justify-end absolute top-5 right-5">
                    <button
                        type="button"
                        className="rounded-full h-[30px] w-[30px] text-sm border-2 border-black font-semibold"
                        onClick={onClose}
                    >
                        <i className="fa-solid text-black text-lg fa-xmark"></i>
                    </button>
                </div>

                <pre className="bg-gray-100 p-2 rounded mt-11"
                    style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word"
                    }}
                >{JSON.stringify(json, null, 2)}</pre>
            </div>
        </div>
    );
};

export default ApiLogsModel;
