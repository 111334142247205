import React from 'react'
import { getUserRoleFromToken } from '../../utils/auth'
import SubAdminTable from '../../components/table/SubAdmin'

const SubAdmin = () => {
  const userType = getUserRoleFromToken()
  return (
    <SubAdminTable title={'Sub Admin'} userType={userType} />
  )
}

export default SubAdmin