import React, {useState} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import apiService from "../../apiService";
import { toast } from "react-toastify";
import useBodyOverflow from "../../hooks/useBodyOverflow";

const ClientModel = ({ isOpen, onClose, fetchData, client }) => {
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
  });

  const initialValues = {
    username: client ? client.name : "",
    email: client ? client.email : "",
  };

  useBodyOverflow(isOpen)

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    const userData = {
      name: values.username,
      email: values.email,
      userType: "client",
    };

    try {
      let response;
      if (client) {
        response = await apiService.auth.updateUser(client._id, userData);
      } else {
        response = await apiService.auth.register(userData);
      }

      if (response?.success) {
        fetchData();
        toast.success(`Client ${client ? "updated" : "created"} successfully!`);
        onClose();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(`Error ${client ? "updating" : "creating"} user:`, error);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  if (!isOpen) return

  return (
    <div className="modal-overlay">
      <div className="modal pt-5 sm:px-8 sm:pb-10 px-4 pb-5 max-h-[90%] overflow-y-auto max-w-[700px] relative mx-auto w-full">
        <div className="flex justify-end absolute top-5 right-5">
          <button
            type="button"
            className="rounded-full h-[30px] w-[30px] text-sm border-2 border-black font-semibold"
            onClick={onClose}
          >
            <i className="fa-solid text-black text-lg fa-xmark"></i>
          </button>
        </div>
        <h2 className="text-center font-bold text-xl leading-6 mt-8">
          {client ? "Edit Client" : "Add Client"}
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, isValid }) => (
            <Form className="mt-10">
              <div className="flex">
                <label className="font-semibold text-base leading-5 flex-[4]">Username</label>
                <div className="flex-[8]">
                  <Field
                    type="text"
                    name="username"
                    placeholder="Username"
                    className="outline-none w-full bg-[#F2F2F2] flex-[8] p-2 font-medium text-sm"
                  />
                  <ErrorMessage name="username" component="div" className="text-red-500 text-sm" />
                </div>
              </div>
              <div className="mt-3 flex">
                <label className="font-semibold text-base leading-5 flex-[4]">Email</label>
                <div className="flex-[8]">
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="outline-none w-full bg-[#F2F2F2] flex-[8] p-2 font-medium text-sm"
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
                </div>
              </div>
              {/* <div className="mt-3 flex">
                  <label className="font-semibold text-base leading-5 flex-[4]">Password</label>
                  <div className="flex-[8] relative">
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password"
                      className="outline-none w-full bg-[#F2F2F2] flex-[8] p-2 font-medium text-sm"
                    />
                    <ErrorMessage name="password" component="div" className="text-red-500 text-sm" />
                    <i
                      className={`fa-solid ${passwordVisible ? " fa-eye" : "fa-eye-slash"} absolute right-4 top-3 transform  cursor-pointer`}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    ></i>
                  </div>
                </div> */}
              <div className="mt-10 flex justify-center gap-2">
                <button
                  type="submit"
                  className={`py-3 px-8 rounded-[15px] bg-black text-white text-sm font-semibold ${!isValid ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  disabled={isSubmitting || !isValid}
                >
                  {loading ? (
                    <div className="loader" />
                  ) : client ? (
                    "Update"
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ClientModel;
