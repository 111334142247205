import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import ToastNotification from "../components/shared/ToastNotification";
import { Suspense } from "react";
import 'react-loading-skeleton/dist/skeleton.css'
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown/style.css';
import { Loader } from "../components/shared/Loader";

const AuthenticatedLayout = () => {
  return (
    <>
      <ToastNotification />
      <Header />
      <Suspense fallback={<Loader />}>
        <main className="min-h-[calc(100vh-120px)]">
          <div className="min-h-screen flex">
            <Sidebar />
            <main
              style={{ minHeight: '90svh' }}
              className="lg:ml-64 w-full p-5 bg-[#F9F9F9] overflow-y-auto">
              <Outlet />
            </main>
          </div>
        </main>
      </Suspense>
    </>
  );
};

export default AuthenticatedLayout;
