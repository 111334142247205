import React, { useEffect, useState, useCallback } from "react";
import ApiService from '../../apiService';
import SkeletonLoader from "../skeletonloader/SkeletonLoader";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { roleList, options, onlyAdminStatus, allStatus } from '../../utils/constant';
import apiService from "../../apiService";
import { isAdmin, isStaffVerifier } from "../../utils/auth";
import Pagination from "../shared/Pagination";
const Dropdown = React.lazy(() => import('react-dropdown'));

const RequestTable = ({ title, userType }) => {
    const [loading, setLoading] = useState(false);
    const [leads, setLeads] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const [leadToDelete, setLeadToDelete] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    const [selectedStatus, setSelectedStatus] = useState(options[0].value);
    const [selectedRole, setSelectedRole] = useState(roleList[0].value);
    const [selectedUser, setSelectedUser] = useState('');
    const [assigneeList, setAssigneeList] = useState([]);

    const isUserAdmin = isAdmin();
    const [userList, setUserList] = useState([]);
    const navigate = useNavigate();

    const fetchData = useCallback(async (page = 1) => {
        setLoading(true);
        try {
            const result = await ApiService.leads.getLeads(
                { userType, selectedStatus, page, selectedUser, rowsPerPage, assigned: true }
            );
            if (result?.success) {
                const { data } = result;
                setLeads(data.leads || []);
                setTotalPages(data.totalPages || 1);
                setCurrentPage(data.currentPage || 1);
                setTotalData(data.totalLeads);
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }, [selectedStatus, selectedUser, userType, rowsPerPage]);

    const fetchAssigneeData = useCallback(async () => {
        const assignee = await apiService.auth.getUser(userType, '', -1, 'verifier');
        const options = assignee?.data?.users?.map((ass) => ({ label: ass.name, value: ass._id }));
        setAssigneeList(options || []);
    }, [userType]);

    useEffect(() => {
        fetchAssigneeData();
    }, [fetchAssigneeData]);

    const fetchUserList = useCallback(async () => {
        if (selectedRole && selectedRole !== 'all') {
            const { data } = await apiService.auth.getUser(selectedRole);
            const userOption = data?.users.map((user) => ({ label: user.name, value: user._id }));
            setUserList(userOption);
        } else {
            setUserList([]);
            setSelectedUser('');
        }
    }, [selectedRole]);

    useEffect(() => {
        fetchUserList();
    }, [fetchUserList, selectedRole]);

    useEffect(() => {
        fetchData(currentPage);
    }, [fetchData, currentPage, selectedStatus, selectedUser, rowsPerPage]);

    // const handleEditLead = (lead) => {
    //     navigate(`/crm/leads/edit/${lead.leadId}`);
    // };

    // const openDeleteConfirmation = (leadId) => {
    //     setLeadToDelete(leadId);
    //     setIsDeleteConfirmationOpen(true);
    //     document.body.style.overflow = 'hidden';
    // };

    const closeDeleteConfirmation = () => {
        setIsDeleteConfirmationOpen(false);
        setLeadToDelete(null);
        document.body.style.overflow = 'unset';
    };

    const handleDelete = async () => {
        if (!leadToDelete) return;
        setIsDeleting(true);
        try {
            await ApiService.leads.deleteLeads(leadToDelete);
            fetchData();
            toast.success('Lead deleted successfully!');
        } catch (error) {} finally {
            setIsDeleting(false);
            closeDeleteConfirmation();
        }
    };

    const handleDropdownChange = (option) => {
        setSelectedStatus(option.value);
    };

    const handleUserChange = (option) => {
        setSelectedUser(option.value);
    };

    const handleRoleChange = (option) => {
        setSelectedRole(option.value);
    };

    const onAssigneeChange = async (assigneeId, leadId) => {
        try {
            const updatedLead = await apiService.leads.updateLead(leadId, { verifier: assigneeId, status: 'pending' });
            if (updatedLead.success) {
                toast.success(updatedLead.message);
                setLeads((prevLeads) =>
                    prevLeads.map((lead) =>
                        lead.leadId === leadId ? { ...lead, verifier: assigneeId } : lead
                    )
                );
            }
        } catch (error) {}
    };

    const isAssigneeDisabled = (status) => {
        return status !== "submitted";
    };

    const getAssigneeLabel = (id) => {
        let label = assigneeList.find((user) => user.value === id)?.label;
        return label || '';
    };


    const getLeadStatus = (status) => {
        if (userType !== 'admin' && onlyAdminStatus.includes(status)) {
            return 'Submitted To Attorney'
        }

        return allStatus.find((d) => d.value === status)?.label || status
    }


    return (
        <>
            <div className="flex sm:justify-end justify-between gap-2">
                {isUserAdmin && <Dropdown
                    options={roleList}
                    onChange={handleRoleChange}
                    value={selectedRole}
                    placeholder="Select an Role"
                    className="font-medium shrink-0"
                />}
                {isUserAdmin &&
                    <Dropdown
                        options={userList}
                        onChange={handleUserChange}
                        value={selectedUser}
                        placeholder="Select User"
                        className="font-medium shrink-0"
                        disabled={selectedRole === 'all'}
                    />
                }
                <Dropdown
                    options={options}
                    onChange={handleDropdownChange}
                    value={selectedStatus}
                    placeholder="Select Option"
                    className=" font-medium  shrink-0 min-w-dropdown"
                />
            </div>

            <div className="border bg-white border-border-dark mt-4 rounded-[20px] overflow-hidden">
                <div className="flex items-center py-1 px-4 justify-center relative">
                    <h1 className="font-bold p-2 text-center leading-8 text-2xl">{title}</h1>
                </div>

                <div className="w-full overflow-x-auto border-t border-border-dark min-h-[300px]">
                    {loading && (
                        <SkeletonLoader />
                    )}
                    {!loading && (leads.length === 0 ? (
                        <p className="text-center py-4">No leads found</p>
                    ) : (
                        <table className="w-full border-collapse scrolldown">
                            <thead>
                                <tr className="bg-[#F6F6F6] h-[41.9px]">
                                    <th className="text-base font-bold px-4 border-r border-border-dark w-[5%]">S.No.</th>
                                    <th className="text-base font-bold px-4 border-r border-border-dark w-[20%]">Username</th>
                                    <th className="text-base font-bold px-4 border-r border-border-dark w-[25%]">Email</th>
                                    {isUserAdmin && <th className="text-base font-bold px-4 border-r border-border-dark w-[20%]">Assignee</th>}
                                    <th className="text-base font-bold px-4 border-r border-border-dark w-[10%]">Status</th>
                                    {/* <th className="text-base font-bold px-4 border-r border-border-dark w-[30%]">Action</th> */}
                                </tr>
                            </thead>
                            <tbody className="bg-white w-full">
                                {leads?.map((lead, index) => (
                                    <tr key={lead.leadId} className="border-b border-border-dark" style={{ cursor: isStaffVerifier() ? "pointer" : "default" }} onClick={() => {
                                        if (isStaffVerifier()) {
                                            navigate(`/crm/lead/verifier/${lead.leadId}`);
                                        }
                                    }}>
                                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                                            {(currentPage - 1) * rowsPerPage + index + 1}
                                        </td>
                                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                                            {lead?.createdBy.name}
                                        </td>
                                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark">
                                            {lead?.createdBy?.email}
                                        </td>
                                        {isUserAdmin && <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark" style={{ textTransform: "capitalize" }}>
                                            {isAssigneeDisabled(lead?.status) ? getAssigneeLabel(lead?.verifier) || '' : <Dropdown
                                                options={[{ label: "Assignee", value: "" }, ...assigneeList]}
                                                onChange={(e) => {
                                                    onAssigneeChange(e.value, lead.leadId);
                                                }}
                                                value={getAssigneeLabel(lead?.verifier)}
                                                placeholder="Select an Assignee"
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                className="font-medium"
                                                disabled={lead.status === 'pending'}
                                            />}
                                        </td>}
                                        <td className="text-sm text-center font-bold px-2 py-3 border-r border-border-dark" style={{ textTransform: "capitalize" }}>
                                            {getLeadStatus(lead.status)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ))}
                </div>

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    rowsPerPage={rowsPerPage}
                    onPageChange={setCurrentPage}
                    totalData={totalData}
                    loading={loading}
                    onRowsPerPageChange={(e) => {
                        setRowsPerPage(+e.target.value);
                        setCurrentPage(1);
                    }}
                />

                {isDeleteConfirmationOpen && (
                    <div className="fixed inset-0 z-[1000] bg-black bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white rounded-lg p-4">
                            <p className="text-lg font-semibold py-10">Are you sure you want to delete this lead?</p>
                            <div className="flex justify-end mt-4 font-semibold">
                                <button className={`bg-[#AC1218] text-white py-1 px-2 text-sm font-semibold rounded-md ${isDeleting ? 'opacity-50' : ''}`} onClick={handleDelete} disabled={isDeleting}>
                                    {isDeleting ? 'Deleting...' : 'Delete'}
                                </button>
                                <button className="ml-4 border border-border-color py-1 px-2 text-sm rounded-md" onClick={closeDeleteConfirmation}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default RequestTable;
