import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ApiService from "../../apiService";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserRoleFromToken } from "../../utils/auth";

import DatePicker from "react-datepicker";

const LeadForm = ({ isEditMode }) => {
  const { id: leadId, campId } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const campaignId = query.get("campaignId");
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [loading, setLoading] = useState(false);
  const [camp, setCamp] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [uploadedDoc, setUploadedDoc] = useState(null);
  const [uploadedRecording, setUploadedRecording] = useState(null);
  const navigate = useNavigate();
  const userType = getUserRoleFromToken();

  useEffect(() => {
    if (campaignId && camp.length) {
      const matchedCampaign = camp.find((d) => d.value === campaignId);
      if (matchedCampaign) {
        fetchQuestions(matchedCampaign.value);
      }
    }
  }, [camp, campaignId]);

  useEffect(() => {
    if (isEditMode && leadId) {
      fetchLeadData(leadId);
    }
  }, [leadId, isEditMode]);

  useEffect(() => {
    fetchCamps();
  }, []);

  useEffect(() => {
    if (campId || campaignId) {
      fetchQuestions(campId || campaignId);
    }
  }, [camp, campId, campaignId]);

  const fetchQuestions = async (campId) => {
    setLoading(true);
    try {
      const result = await ApiService.question.getQuestionsByCampId(campId);
      if (result?.success) {
        setQuestions(result.data || []);
      }
    } catch (error) {
      toast.error("Failed to fetch questions.");
    } finally {
      setLoading(false);
    }
  };

  const fetchLeadData = async (id) => {
    setLoading(true);
    try {
      const result = await ApiService.leads.getLeadById(id, "vendor");
      if (result?.success) {
        const { data } = result;
        formik.setValues({
          ...formik.values,
          ...data.responses.reduce((acc, response) => {
            acc[response.questionId] = response.response;
            return acc;
          }, {}),
        });
      }
    } catch (error) {
      toast.error("Failed to fetch lead data.");
    } finally {
      setLoading(false);
    }
  };

  const fetchCamps = async () => {
    try {
      const data = await ApiService.campaigns.getById(campId || campaignId);
      if (data?.success) {
        setCamp(
          data.data.map((camp) => ({ label: camp.title, value: camp._id }))
        );
      }
    } catch (error) {
      toast.error("Failed to fetch campaigns.");
    }
  };

  const initialValues = questions.reduce((acc, question) => {
    acc[question.questionId] = question.type === "radio" ? "Yes" : "";
    return acc;
  }, {});

  const validateDateFormat = (value) => {
    const dateRegex = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/;
    return dateRegex.test(value);
  };

  const validationSchema = Yup.object(
    questions.reduce((acc, question) => {
      if (question.isRequired) {
        acc[question.questionId] =
          question.type === "date"
            ? Yup.string()
              .test(
                "is-valid-date",
                `${question.title} must be in mm-dd-yyyy format`,
                validateDateFormat
              )
              .required(`${question.title} is required`)
            : question.type === "number"
              ? Yup.number().required(`${question.title} is required`)
              : question.type === "email"
                ? Yup.string()
                  .email("Invalid email address")
                  .required(`${question.title} is required`)
                : Yup.string().required(`${question.title} is required`);
      }
      return acc;
    }, {})
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      if (!isEditMode && userType === "vendor" && !uploadedRecording) {
        toast.error("Both Document and Recording are required for vendors.");
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append("timeZone", userTimeZone || '');
      formData.append("campaignId", campId || campaignId);

      Object.keys(values).forEach((questionId) => {
        if (values[questionId]) {
          formData.append(
            "responses[]",
            JSON.stringify({
              questionId,
              response: values[questionId],
            })
          );
        }
      });

      if (uploadedDoc) formData.append("media", uploadedDoc);
      if (uploadedRecording) formData.append("media", uploadedRecording);

      try {
        let response;
        if (isEditMode) {
          response = await ApiService.leads.updateLead(leadId, formData);
        } else {
          response = await ApiService.leads.createLead(formData);
        }

        if (response?.success) {
          toast.success(
            isEditMode
              ? "Lead updated successfully!"
              : "Lead created successfully!"
          );
          navigate("/crm/leads");
        }
      } catch (error) {
        toast.error("Failed to save lead.");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (type === "doc") {
      setUploadedDoc(file);
    } else if (type === "recording") {
      setUploadedRecording(file);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="p-3">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
        {questions?.map((question) => (
          <div key={question.questionId} className="form-group mt-5">
            <label className="text-lg font-medium">
              {question.title}{" "}
              {question.isRequired && <span className="text-red-500">*</span>}
            </label>
            {question.type === "text" && (
              <input
                type="text"
                name={question.questionId}
                value={formik.values[question.questionId]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="outline-none w-full mt-2 border border-[#C4C4C4] bg-[#F9F9F9] p-2 rounded-md font-medium text-sm"
              />
            )}
            {question.type === "radio" && (
              <div className="control mt-1 flex gap-3 items-center">
                <label className="radio flex gap-1 items-center">
                  <input
                    type="radio"
                    name={question.questionId}
                    value="Yes"
                    checked={formik.values[question.questionId] === "Yes"}
                    onChange={formik.handleChange}
                  />
                  Yes
                </label>
                <label className="radio flex gap-1 items-center">
                  <input
                    type="radio"
                    name={question.questionId}
                    value="No"
                    checked={formik.values[question.questionId] === "No"}
                    onChange={formik.handleChange}
                  />
                  No
                </label>
              </div>
            )}
            {question.type === "number" && (
              <input
                type="number"
                name={question.questionId}
                value={formik.values[question.questionId]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="outline-none w-full mt-2 border border-[#C4C4C4] bg-[#F9F9F9] p-2 rounded-md font-medium text-sm"
              />
            )}
            {question.type === "email" && (
              <input
                type="email"
                name={question.questionId}
                value={formik.values[question.questionId]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="outline-none w-full mt-2 border border-[#C4C4C4] bg-[#F9F9F9] p-2 rounded-md font-medium text-sm"
              />
            )}
            {question.type === "date" && (
              <div>
                <DatePicker
                  selected={
                    validateDateFormat(formik.values[question.questionId])
                      ? new Date(formik.values[question.questionId].replace(/-/g, "/"))
                      : null
                  }
                  onChange={(date) => {
                    if (date) {
                      const formattedDate = format(date, "MM-dd-yyyy");
                      formik.setFieldValue(question.questionId, formattedDate);
                      formik.setFieldError(question.questionId, undefined); // Clear error if valid
                    }
                  }}
                  onChangeRaw={(e) => {
                    formik.setFieldValue(question.questionId, e.target.value);
                  }}
                  onBlur={() => {
                    const value = formik.values[question.questionId];
                    if (value && !validateDateFormat(value)) {
                      formik.setFieldError(
                        question.questionId,
                        "Invalid date format. Use mm-dd-yyyy."
                      );
                      formik.setFieldTouched(question.questionId, true);
                    }
                  }}
                  dateFormat="MM-dd-yyyy"
                  placeholderText="mm-dd-yyyy"
                  className="outline-none w-full mt-2 border border-[#C4C4C4] bg-[#F9F9F9] p-2 rounded-md font-medium text-sm"
                  wrapperClassName="w-full"
                />
              </div>
            )}
            {formik.touched[question.questionId] &&
              formik.errors[question.questionId] && (
                <p className="text-red-500 text-sm mt-1">
                  {formik.errors[question.questionId]}
                </p>
              )}
          </div>
        ))}
      </div>

      {!loading && (
        <div className="relative border border-[#C4C4C4] rounded-[5px] px-3 py-4 mt-[34px]">
          <span className="absolute top-2 left-1 font-semibold text-base leading-5 transform translate-y-[-100%] bg-white text-black">
            Upload Documentation
          </span>
          {questions.length && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
              <div className="form-group mt-5 flex-col flex">
                <label className="text-lg font-medium">Upload Document</label>
                {/* <input
                    type="file"
                    onChange={(e) => handleFileChange(e, "doc")}
                    className="mt-2 border border-[#C4C4C4] p-2 rounded-md"
                  /> */}

                {/* custom file start  */}

                <div className="flex items-center justify-center w-full mt-2 ">
                  <label
                    className="flex flex-col items-center justify-center w-full  border rounded-md cursor-pointer bg-white overflow-hidden   border-border-dark"
                  >
                    <div className="flex flex-col items-center justify-center bg-[#F9F9F9] w-full pt-4 pb-4">
                      <svg
                        className="w-8 h-8 mb-2 text-black dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-1 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold text-black">
                          {uploadedDoc?.name ? uploadedDoc.name : 'Click to upload Document'}
                        </span>
                      </p>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      accept=".pdf, .doc, .docx"
                      onChange={(e) => handleFileChange(e, "doc")}
                    />
                  </label>
                </div>

                {/* custom file end */}
              </div>

              <div className="form-group mt-5 flex-col flex">
                <label className="text-lg font-medium">
                  Upload Recording{" "}
                  {userType === "vendor" && (
                    <span className="text-red-600">*</span>
                  )}
                </label>
                {/* <input
                    type="file"
                    onChange={(e) => handleFileChange(e, "recording")}
                    accept="audio/*, video/*"
                    className="mt-2 border border-[#C4C4C4] p-2 rounded-md"
                  /> */}

                {/* custom file start  */}

                <div className="flex items-center justify-center w-full mt-2 ">
                  <label
                    className="flex flex-col items-center justify-center w-full  border rounded-md cursor-pointer bg-white overflow-hidden   border-border-dark"
                  >
                    <div className="flex flex-col items-center justify-center bg-[#F9F9F9] w-full pt-4 pb-4">
                      <svg
                        className="w-8 h-8 mb-2 text-black dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-1 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold text-black">
                          {uploadedRecording?.name ? uploadedRecording?.name : 'Click to upload Recording'}
                        </span>
                      </p>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      accept="audio/*, video/*"
                      onChange={(e) => handleFileChange(e, "recording")}
                    />
                  </label>
                </div>

                {/* custom file end */}
              </div>
            </div>
          )}
        </div>
      )}
      {/* Add upload fields and submit button similar to your original code */}
      <>
        <div className="flex mt-6 justify-center">
          <button
            type="submit"
            className={`py-3 px-8 rounded-[15px] bg-black text-white text-sm font-semibold ${loading ||
              !formik.isValid ||
              (userType === "vendor" && !uploadedRecording)
              ? "opacity-50 cursor-not-allowed"
              : ""
              }}`}
            disabled={
              loading ||
              !formik.isValid ||
              (!isEditMode && userType === "vendor" && !uploadedRecording)
            }
          >
            {loading ? (
              <div className="loader" />
            ) : isEditMode ? (
              "Update Lead"
            ) : (
              "Create Lead"
            )}
          </button>
        </div>
      </>
    </form>
  );
};

export default LeadForm;
