import axios from '../helpers/axios';
import { handleErrorToaster } from '../utils/function';

class DashboardService {
    async getUserLeadDetails({ status = "", userType = "", userId = "", timeline, startDate = "", endDate = ''}) {
        try {
            const response = await axios.get(`/dashboard/user-details?status=${status}&timeline=${timeline}&startDate=${startDate}&endDate=${endDate}&id=${userId}&role=${userType}`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            return false;
        }
    }

    async getBarChartData({ status = "", userType = "", userId = "", timeline, startDate = "", endDate = '' }) {
        try {
            const response = await axios.get(`/dashboard/bar-chart-data?status=${status}&timeline=${timeline}&startDate=${startDate}&endDate=${endDate}&id=${userId}&role=${userType}`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            return false;
        }
    }

    async getPieChartData({ status = "", userType = "", userId = "", timeline, startDate = "", endDate = ""}) {
        try {
            const response = await axios.get(`/dashboard/pie-chart-data?status=${status}&timeline=${timeline}&startDate=${startDate}&endDate=${endDate}&id=${userId}&role=${userType}`);
            return response.data;
        } catch (error) {
            handleErrorToaster(error)
            return false;
        }
    }
}

export default new DashboardService();
