import React, { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { getUserMenuByRole, getUserRoleFromToken } from '../../utils/auth'

const Sidebar = () => {
  const userRole = getUserRoleFromToken()

  const getUserMenu = useCallback(()=> {
    return getUserMenuByRole(userRole)
  }, [userRole])

  return (
    <aside className="bg-white min-h-full max-lg:hidden w-64 border-r left-0 border-border-color fixed h-full ">
      <ul>
        {getUserMenu().map((item, index) => (
          <li key={index}>
            <NavLink
              to={item.href}
              className={({ isActive }) =>
                `navlist flex items-center py-2 p-5 ${isActive ? "!text-white font-bold bg-[#7FB8EF]" : " text-black"}`
              }
            >
              <img src={item.icon} alt={item.name} className="h-5 w-5" />
              <span>{item.name}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </aside>
  )
}

export default Sidebar
