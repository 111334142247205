import React, { useEffect, useState } from "react";
import apiService from "../../apiService";
import { toast } from "react-toastify";
import ClientView from "../skeletonloader/ClientView";
import useBodyOverflow from "../../hooks/useBodyOverflow";

const Dropdown = React.lazy(() => import('react-dropdown'));

const ClientViewModel = ({ isOpen, onClose, fetchData, lead }) => {
  const [clientsList, setClientsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientData, setClientData] = useState([]);
  const [loadingClientData, setLoadingClientData] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [isError, setIsError] = useState(false);

  const fetchClients = async () => {
    try {
      const result = await apiService.user.getClients();
      const options = result?.data?.clients?.map((client) => ({
        label: client.name,
        value: client._id,
      }));
      setClientsList(options);
    } catch (error) {
      console.error("Error fetching clients", error);
      toast.error("Failed to fetch clients.");
    }
  };

  const fetchClientDetails = async (clientId) => {
    setLoadingClientData(true);
    try {
      const result = await apiService.user.getClientConfiguration({
        clientId,
        leadId: lead.leadId,
      });
      setClientData(result?.data?.configuration.requestBody);
    } catch (error) {
      console.error("Error fetching client details", error);
      toast.error("Failed to fetch client details.");
    } finally {
      setLoadingClientData(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchClients();
    }
  }, [isOpen]);

  useBodyOverflow(isOpen)

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption.value);
    if (selectedOption.value) {
      fetchClientDetails(selectedOption.value);
    }
  };

  const resetForm = () => {
    setSelectedClient(null)
    setClientData([])
    setIsError(false)
    setSubmitResponse(null)
    
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedClient || !clientData?.length) {
      toast.error("Please select a client and ensure data is loaded.");
      return;
    }

    setLoading(true);
    setIsError(false);
    setSubmitResponse(null);
    try {
      const requestBody = {
        clientId: selectedClient,
        leadId: lead.leadId,
        configuration: {
          requestBody: clientData,
        },
      };

      const result = await apiService.client.sendToClient(requestBody);
      if (result.success) {
        await apiService.leads.updateLead(lead.leadId, { status: "submitted_to_attorney", clientId: selectedClient })
        toast.success("Data sent successfully.");
        setSubmitResponse(result.data);
      }
    } catch (error) {
      setSubmitResponse(error.response?.data || "An error occurred");
      setIsError(true);
    } finally {
      fetchData();
      setLoading(false);
    }
  };

  useEffect(() => { resetForm() }, [])

  return (
    isOpen && (
      <div className="modal-overlay">
        <div className="modal pt-5 sm:px-8 pb-14 max-h-[95%] min-h-[80%] overflow-auto px-4  relative max-w-[700px] mx-auto w-full">
          <div className="flex justify-end absolute top-5 right-5">
            <button
              type="button"
              className="rounded-full h-[30px] w-[30px] text-sm border-2 border-black font-semibold"
              onClick={() => {
                onClose()
                resetForm()
              }}
            >
              <i className="fa-solid text-black text-lg fa-xmark"></i>
            </button>
          </div>
          <h2 className="text-center font-bold text-xl leading-6 mt-8">
            Lead Details
          </h2>
          <form onSubmit={handleSubmit} className="mt-10">
            <div className="flex items-center justify-between">
              <div className="flex justify-center gap-2">
                {(isError || !submitResponse) ? <button
                  type="submit"
                  className="py-3 px-8 rounded-[15px] bg-black text-white text-sm font-semibold"
                  disabled={loading}
                >
                  {loading ?
                    <div className="loader"></div>
                    :
                    lead?.clientId && lead?.clientId?.length > 0
                      ? "Resend"
                      : "Send"
                  }
                </button> : ""}
              </div>
              <div className="relative w-full max-w-[160px]">
                <Dropdown
                  options={[
                    { label: "Select client", value: "" },
                    ...clientsList,
                  ]}
                  onChange={handleClientChange}
                  value={selectedClient}
                  placeholder="Select a client"
                  className="font-medium z-50 text-nowrap"
                />
                <i className="fas fa-chevron-down absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"></i>
              </div>
            </div>

            {loadingClientData ? (
              <ClientView />
            ) : (
              clientData?.length > 0 && (
                <div className="mt-6 overflow-x-auto pb-10">
                  <table className="w-full border-collapse lead-table">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="border p-2 text-left font-semibold">
                          Field Name
                        </th>
                        <th className="border p-2 text-left font-semibold">
                          Response
                        </th>
                      </tr>
                    </thead>
                    <tbody className="block max-h-[400px] overflow-y-auto custom-scrollbar pr-1">
                      {clientData?.map((item, index) => (
                        <tr key={index} className="table-row">
                          <td className="border p-2">{item.field_name}</td>
                          <td className="border p-2">{item.response}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            )}

            {submitResponse && (
              <div className={`mt-4 p-4 border-t ${isError ? "text-red-600" : "text-green-600"}`}>
                <h3 className="text-lg font-bold">{isError ? "Error" : "Success"}</h3>
                <pre className="bg-gray-100 p-2 rounded">{JSON.stringify(submitResponse, null, 2)}</pre>
              </div>
            )}
          </form>
        </div>
      </div>
    )
  );
};

export default ClientViewModel;
