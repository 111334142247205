import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import apiService from "../../apiService";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import useBodyOverflow from "../../hooks/useBodyOverflow";

const SubAdminModel = ({ isOpen, onClose, fetchData, userData }) => {
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  // User options
  const [vendorList, setVendorList] = useState([]);
  const [clientList, setClientList] = useState([]);

  const validationSchema = Yup.object({
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password:  !userData ? Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Z]/, "Password must contain an uppercase letter")
      .matches(/[0-9]/, "Password must contain a number")
      .matches(/[!@#$%^&*]/, "Password must contain a special character")
      .required("Please enter your password") : Yup.string(),
    clientIds: Yup.array(),
    vendorIds: Yup.array(),
  });
  
  const initialValues = {
    username: userData ? userData.name : "",
    email: userData ? userData.email : "",
    password: "",
    clientIds: userData ? clientList?.filter((c)=> userData?.AssignedClientsIds?.includes(c?.value))  || [] : [],
    vendorIds: userData ? vendorList?.filter((c)=> userData?.AssignedVendorIds?.includes(c?.value))  || [] : [],
  };

  useBodyOverflow(isOpen)

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    const payload = {
      name: values.username,
      email: values.email,
      password: values.password,
      userType: "subAdmin",
      clientIds: values.clientIds.map((d)=> d.value),
      vendorIds: values.vendorIds.map((d)=> d.value),
    };

    try {
      let response;
      if (userData) {
        response = await apiService.auth.updateUser(userData._id, payload);
      } else {
        response = await apiService.user.createSubAdmin(payload);
      }

      if (response?.success) {
        fetchData();
        toast.success(`Sub Admin ${userData ? "updated" : "created"} successfully!`);
        onClose();
      }
    } catch (error) {
      console.error(`Error ${userData ? "updating" : "creating"} user:`, error);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const fetchOptions = async () => {
    try {
      const vendors = await apiService.user.getUserList("vendor");
      const clients = await apiService.user.getUserList("client");

      setClientList(clients?.data || []);
      setVendorList(vendors?.data || []);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal pt-5 sm:px-8 sm:pb-10 px-4 pb-5 max-h-[90%] overflow-y-auto max-w-[700px] relative mx-auto w-full">
        <div className="flex justify-end absolute top-5 right-5">
          <button
            type="button"
            className="rounded-full h-[30px] w-[30px] text-sm border-2 border-black font-semibold"
            onClick={onClose}
          >
            <i className="fa-solid text-black text-lg fa-xmark"></i>
          </button>
        </div>
        <h2 className="text-center font-bold text-xl leading-6 mt-8">
          {userData ? "Edit Sub Admin" : "Add Sub Admin"}
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, isValid, setFieldValue, values, errors }) => (
            <Form className="mt-10">
              <div className="flex">
                <label className="font-semibold text-base leading-5 flex-[4]">Username</label>
                <div className="flex-[8]">
                  <Field
                    type="text"
                    name="username"
                    placeholder="Username"
                    className="outline-none w-full bg-[#F2F2F2] flex-[8] p-2 font-medium text-sm"
                  />
                  <ErrorMessage name="username" component="div" className="text-red-500 text-sm" />
                </div>
              </div>
              <div className="mt-3 flex">
                <label className="font-semibold text-base leading-5 flex-[4]">Email</label>
                <div className="flex-[8]">
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="outline-none w-full bg-[#F2F2F2] flex-[8] p-2 font-medium text-sm"
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
                </div>
              </div>
              <div className="mt-3 flex">
                <label className="font-semibold text-base leading-5 flex-[4]">Password</label>
                <div className="flex-[8] relative">
                  <Field
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    className="outline-none w-full bg-[#F2F2F2] flex-[8] p-2 font-medium text-sm"
                  />
                  <ErrorMessage name="password" component="div" className="text-red-500 text-sm" />
                  <i
                    className={`fa-solid ${passwordVisible ? "fa-eye" : "fa-eye-slash"} absolute right-4 top-3 transform cursor-pointer`}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  ></i>
                </div>
              </div>
              <div className="relative border border-[#C4C4C4] rounded-[5px] px-3 py-4 mt-[34px]">
                <span className="absolute top-2 left-1 font-semibold text-base leading-5 transform -translate-y-[100%] bg-white text-black">
                  Select Fields
                </span>
                <div className="flex items-center relative">
                  <label className="font-semibold text-base leading-5 flex-[4]">
                    Select Clients
                  </label>
                  <div className="flex-[8]">
                    <Multiselect
                      options={clientList}
                      selectedValues={values.clientIds} // Ensure no undefined values in selectedValues
                      displayValue="label"
                      onSelect={(selectedList) =>
                        setFieldValue("clientIds", selectedList)
                      }
                      onRemove={(selectedList) =>
                        setFieldValue("clientIds", selectedList)
                      }
                      placeholder="Select Clients"
                      style={{
                        chips: {
                          background: "#F6F6F6",
                          color: "black",
                          fontWeight: "600",
                        },
                      }}
                    />

                  </div>
                </div>
                <div className="flex items-center mt-5 relative">
                  <label className="font-semibold text-base leading-5 flex-[4]">
                    Select Vendors
                  </label>
                  <div className="flex-[8]">
                    <Multiselect
                      options={vendorList}
                      selectedValues={values.vendorIds}
                      displayValue="label"
                      onSelect={(selectedList) => setFieldValue("vendorIds", selectedList)}
                      onRemove={(selectedList) => setFieldValue("vendorIds", selectedList)}
                      placeholder="Select Vendors"
                      style={{
                        chips: {
                          background: "#F6F6F6",
                          color: "black",
                          fontWeight: "600",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-10 flex justify-center gap-2">
                <button
                  type="submit"
                  className={`py-3 px-8 rounded-[15px] bg-black text-white text-sm font-semibold ${loading || !isValid ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  disabled={loading || isSubmitting || !isValid}
                >
                  {loading ? (
                    <div className="loader" />
                  ) : userData ? (
                    "Update"
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SubAdminModel;
